<template>
  <div id="clause">
    <div class="title">中文知识网用户使用服务协议</div>
    <div class="content" v-html="content">{{ content }}</div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      content: "",
    };
  },
  async created() {
    let res = await this.$apis.mainServe.getclause();
    this.content = res.content.detail[0].content;
  },
};
</script>

<style lang="scss" scoped>
#clause {
  width: 1200px;
  margin: 20px auto;
  padding-top: 20px;
  padding-bottom: 50px;
  background: #fff;
  box-shadow: 0px 7px 15px 1px rgba(22, 26, 100, 0.13);
  .title {
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
  }
  .content {
    width: 90%;
    margin: 0 auto;
    margin-top: 20px;
  }
}
</style>
